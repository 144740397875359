import React from "react"
import Layout from "../components/Layout"

export default function Shop() {
  return (
    <Layout>
      <p>Need the shopify link</p>
    </Layout>
  )
}
